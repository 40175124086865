import React, {useState, useEffect, useRef} from 'react'
import "./Search.css";

function Search(props) {
  const [input, setInput] = useState('')
  const inputRef = useRef(null)

  useEffect(() => {
    inputRef.current.focus()

  })

  const handleSubmit = e => {
    e.preventDefault();
    props.onSubmit({text: input})
    setInput('');
  };
      
  return (
    <form className="search" onSubmit={handleSubmit}>

      <input
        className="search-input"
        type="text"
        placeholder="Search City"
        name="text"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        ref={inputRef}
        />
      
    </form>
  )
}

export default Search
