import React from 'react'
import { RiCloseCircleLine} from 'react-icons/ri'
import "./WeatherCard.css"
import FlipMove from 'react-flip-move';

function WeatherCard({listOfCards, removeCard}) {

  const cards =  listOfCards.map((card, index) => (
    
    <div className={`container background-tint ${card.weather.toLowerCase()}`} key={card.id}> 
    
      <div className="top">
        <div className="location">
        <div className="icons">
          <RiCloseCircleLine
          onClick={() => removeCard(card.id)}
          className="delete-icon"
          />            
        </div>
          <p>{card.name}, {card.country}: {card.localDateTime}</p>
          <p>Your time: {card.yourDateTime}</p>
          <p>Time Difference: {card.yourTimeDifference} h</p>
        </div>

        <div className="temp">
          {card.temp ? <h1>{card.temp}°C</h1> : null}
        </div>

        <div className="description">
          {card.weather ? <p>{card.weather}</p> : null} 
        </div>
      </div>

      {card.name !== undefined &&
        <div className="bottom">
          <div className="feels">
            {card.feelsLike ? <p className="bold">{card.feelsLike}°C</p> : null}
            <p>Feels Like</p>
          </div>
          <div className="humidity">
            {card.humidity ? <p className="bold">{card.humidity}%</p> : null}
            <p>Humidity</p>
          </div>
          <div className="wind">
            {card.windSpeed ? <p className="bold">{card.windSpeed} km/h</p> : null}
            <p>Wind Speed</p>
          </div>
        </div>
      }
    
    </div>
  ));
  return (
    <div>
      <FlipMove duration={300} easing="ease-in-out">
      {cards}
      </FlipMove >
    </div>
  )
}

export default WeatherCard