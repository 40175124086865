import './App.css';
import React, {useState} from 'react'
import Search from './components/Search';
import WeatherCard from './components/WeatherCard';
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid';
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import LogoDevIcon from '@mui/icons-material/LogoDev';

const api = {
  key: "4a4dec2c4323bffafa8b0f15600e9807",
  baseURL: "https://api.openweathermap.org/data/2.5/",
  accuKey: "GXHT2XXFdYuZ4bor4lfEjyqBemdAxeiC",
  accuBaseURL: "http://dataservice.accuweather.com/",
  accuCitySearch: "locations/v1/cities/search",
  accuForecast: "forecasts/v1/daily/1day/"
}
//`http://dataservice.accuweather.com/locations/v1/cities/search}?apikey=GXHT2XXFdYuZ4bor4lfEjyqBemdAxeiC&q=Turku&language=en-gb&details=true`

function App() {
  const [error, setError] = useState(null)
  const [listOfCards, setListOfCards] = useState([])
  
  const search = async (event) => {

      try {
          const request1 = await axios.get(`${api.accuBaseURL}${api.accuCitySearch}?apikey=${api.accuKey}&q=${event.text}&language=en-gb&details=true`);
          const request2 = await axios.get(`${api.accuBaseURL}${api.accuForecast}${request1.data[0].Key}?apikey=${api.accuKey}&language=en-gb&details=true&metric=true`);
          console.log(request2.data.DailyForecasts);
          //const newCards = [request2.data, ...listOfCards]
          //setListOfCards(newCards)
      }
      catch(error){
          console.log('ERROR', error)  
      }

      try {
        const request2 = await axios.get(`${api.baseURL}weather?q=${event.text}&units=metric&APPID=${api.key}`);
        setError(null)
        let timezone = request2.data.timezone
        let dateTime = new Date();
        let dateTimeUTC = dateTime.getTime() + (dateTime.getTimezoneOffset()*60000)
        var localTime = new Date((timezone*1000) + dateTimeUTC)
      
        const dataObj = {}
        dataObj.id = uuidv4()
        dataObj.name = request2.data.name
        dataObj.temp = request2.data.main ? Math.round(request2.data.main.temp) : null
        dataObj.weather = request2.data.weather ? request2.data.weather[0].main : null 
        dataObj.feelsLike = request2.data.main ? Math.round(request2.data.main.feels_like): null
        dataObj.humidity = request2.data.main ? request2.data.main.humidity: null
        dataObj.windSpeed = request2.data.wind ? Math.round(request2.data.wind.speed) : null
        dataObj.country = request2.data.sys.country 
        dataObj.yourDateTime = dateTime.toLocaleDateString('en-GB', {day:'numeric', month:'numeric', year:'numeric', hour:'2-digit', minute:'2-digit', timeZoneName:'short'})
        dataObj.localDateTime = localTime.toLocaleDateString('en-GB', {day:'numeric', month:'numeric', year:'numeric', hour:'2-digit', minute:'2-digit'})
        dataObj.yourTimeDifference = (localTime - dateTime)/3600000

        const newCards = [dataObj, ...listOfCards]
        setListOfCards(newCards)
      }
      catch(error){
        console.log('ERROR', error)
        console.log('RES', error.response.data)
        if (error.response.data.cod !== '200') setError(error.response.data.message)
      }
  }

  const removeCard = id => {
    const removeArr = [...listOfCards].filter(card => card.id !== id);
    setListOfCards(removeArr);
  }

  return (
    <div className="app">
      <div className="header">

        <div className="header-left">
          <LogoDevIcon className="logo-icon" />
        </div>

        <div className="header-center">
          <div className="header-search">
          <SearchIcon className="search-icon"/>
          <Search onSubmit={search} />
          </div> 
        </div>

        <div className="header-right">
          <MenuIcon className="menu-icon" />
        </div>

      </div>
    
      <div className="card-container">
      { error && <div className="error"> {error} </div>}
      <WeatherCard
      listOfCards={listOfCards}
      removeCard={removeCard}
      />
      </div>

    </div>
  );
}

export default App;
